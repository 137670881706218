export const pairing_sample_text =
  'e.g. chocolate brown high-waisted wide-leg jeans, and a beige corduroy jacket';
export const styling_sample_text =
  'e.g. the shirt is tucked in, and the sleeves are cuffed for a cool  and casual look';
export const footwear_sample_text = 'e.g. brown open-toe sandals';
export const accessories_sample_text = 'e.g. chunky gold hoop earrings';
export const bag_sample_text = 'e.g. a black leather tote bag';

export const CreatorToolStatusImagesGenerated = 'images_generated';
export const CreatorToolStatusInit = 'init';
export const CreatorToolStatusPromptGenerated = 'prompt_generated';
export const CreatorToolStatusGeneratingImage = 'generating_images';
export const CreatorToolStatusImagesSubmitted = 'images_submitted';
export const CreatorToolStatusFailed = 'failed';
export const CreatorToolStatusImagesApproved = 'images_approved';
export const CreatorToolStatusImagesRejected = 'images_rejected';
