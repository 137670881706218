import MenuIcon from '@mui/icons-material/Menu';
import {
  AppBar,
  Container,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, Navigate, useNavigate, createSearchParams } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { doSignOut } from '../firebase/auth';
import { v4 as uuidv4 } from 'uuid';

function Navbar() {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const { currentUser, userLoggedIn } = useAuth();
  const navigate = useNavigate();

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleLogout = () => {
    doSignOut();
    <Navigate to={'/login'} replace={true} />;
  };

  const redirectToLogin = () => {
    <Navigate to={'/login'} replace={true} />;
  };

  const handleLogoClick = () => {
    const newId = uuidv4();
    navigate({
      pathname: '/app',
      search: createSearchParams({ id: newId }).toString(),
    });
  };

  return (
    <div>
      <AppBar position="static" sx={{ height: '4rem' }}>
        <Toolbar>
          <Container
            maxWidth="xl"
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}
          >
            <Typography
              variant="h6"
              component="div"
              onClick={handleLogoClick}
              sx={{ textDecoration: 'none', color: 'inherit', cursor: 'pointer' }}
            >
              Alle Creator Tool
            </Typography>
            <div>
              <IconButton color="inherit" edge="end" onClick={toggleDrawer(true)}>
                <MenuIcon />
              </IconButton>
            </div>
          </Container>
        </Toolbar>
      </AppBar>

      <Drawer anchor="right" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        <Typography>{currentUser?.email}</Typography>
        <List sx={{ width: 250 }}>
          {userLoggedIn ? (
            <ListItem button onClick={handleLogout}>
              <ListItemText primary="Logout" />
            </ListItem>
          ) : (
            <ListItem button onClick={redirectToLogin}>
              <ListItemText primary="Login" />
            </ListItem>
          )}
        </List>
      </Drawer>
    </div>
  );
}

export default Navbar;
