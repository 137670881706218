import React, { useState, useEffect } from 'react';
import { getUserDashboard } from '../apis/storefront';
import { useAuth } from '../contexts/authContext';
import {
  Typography,
  Paper,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  CircularProgress,
} from '@mui/material';

const Dashboard = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const { alleUserId } = useAuth();

  useEffect(() => {
    const fetchDashboardData = async () => {
      if (!alleUserId) {
        setError('User ID is missing');
        setLoading(false);
        return;
      }

      try {
        const response = await getUserDashboard(alleUserId);
        if (response && response.data) {
          setDashboardData(response.data);
        } else {
          throw new Error('Invalid response data');
        }
      } catch (err) {
        console.error('Error fetching dashboard data:', err);
        setError('Failed to fetch dashboard data');
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [alleUserId]);

  if (loading) {
    return <CircularProgress />;
  }

  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (!dashboardData) {
    return <Typography>No dashboard data available</Typography>;
  }

  const {
    total_submissions = 0,
    total_approved = 0,
    total_rejected = 0,
    total_commission = 0,
    product_clicks = [],
    reject_reasons = {},
  } = dashboardData;

  return (
    <Grid container spacing={2} sx={{ width: '100%' }}>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1">Total Submissions</Typography>
          <Typography variant="h4">{total_submissions}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1">Total Approved</Typography>
          <Typography variant="h4">{total_approved}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1">Total Rejected</Typography>
          <Typography variant="h4">{total_rejected}</Typography>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={3} md={3} lg={3} xl={3}>
        <Paper sx={{ p: 2 }}>
          <Typography variant="subtitle1">Total Commissions</Typography>
          <Typography variant="h4">{total_commission}</Typography>
        </Paper>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Product Clicks
        </Typography>
        {product_clicks.length > 0 ? (
          <Grid container spacing={2}>
            {product_clicks.map((product) => (
              <Grid item key={product.external_id} xs={12} sm={6} md={4} lg={3}>
                <Paper
                  sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                >
                  {product.image_url ? (
                    <img
                      src={product.image_url}
                      alt="Product"
                      style={{ width: '100%', height: 'auto', marginBottom: '8px' }}
                    />
                  ) : (
                    <Typography>No image available</Typography>
                  )}
                  <Typography variant="caption">Clicks: {product.count || 0}</Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        ) : (
          <Typography>No product clicks data available</Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>
          Reject Reasons
        </Typography>
        {Object.keys(reject_reasons).length > 0 ? (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Reason</TableCell>
                  <TableCell align="right">Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {Object.entries(reject_reasons).map(([reason, count]) => (
                  <TableRow key={reason}>
                    <TableCell component="th" scope="row">
                      {reason}
                    </TableCell>
                    <TableCell align="right">{count}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No reject reasons data available</Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
