import React, { useEffect } from 'react';
import {
  Box,
  Button,
  Container,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
  CircularProgress,
} from '@mui/material';
import { CheckCircleOutline as CheckCircleOutlineIcon } from '@mui/icons-material';
import GarmentImage from './garment_image';
import Step2 from './reference_image';
import StyleDescription from './style_description';
import Verify from './generation';
import { getConfig, initCreation, getGeneration } from '../apis/storefront';
import Prompt from './final_prompt.jsx';
import { useAuth } from '../contexts/authContext';
import * as constants from './constants.jsx';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';

const steps = [
  { label: 'Garment Image', description: 'Select a garment image' },
  { label: 'Reference Image', description: 'Select a reference image' },
  { label: 'Styling Description', description: 'Describe the styling you want' },
  { label: 'Generate', description: 'Generate your image' },
];

const stepComponents = [GarmentImage, Step2, StyleDescription, Verify];

export default function GenerationFlow({ id }) {
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState(0);
  const [generatedData, setGeneratedData] = React.useState({});
  const [isLoading, setIsLoading] = React.useState(false);
  const [isGenerated, setIsGenerated] = React.useState(false);
  const [config, setConfig] = React.useState({});
  const [generation, setGeneration] = React.useState({});

  const { userLoggedIn, currentUser, alleUserId } = useAuth();

  useEffect(() => {
    getGeneration(alleUserId, id)
      .then((response) => {
        let status = response.data.generation_status;
        if (status === constants.CreatorToolStatusPromptGenerated) {
          setIsGenerated(true);
          setGeneration(response.data);
        } else {
          setActiveStep(0);
          setGeneratedData({});
          setIsLoading(false);
          setIsGenerated(false);
          setGeneration({});
          const newId = uuidv4();
          navigate(`/app?id=${newId}`);
        }
      })
      .catch((error) => {
        setActiveStep(0);
        setGeneratedData({});
        setIsLoading(false);
        setIsGenerated(false);
        setGeneration({});
      });
  }, [id]);

  const enableNext = () => {
    switch (activeStep) {
      case 0:
        return generatedData?.garment != null;
      case 1:
        return generatedData?.reference != null;
      case 2:
        return (
          generatedData?.styling_description?.pairing != null &&
          generatedData?.styling_description?.pairing !== ''
        );
      case 3:
        return true;
      default:
        return false;
    }
  };

  const enableFinish = () => {
    return (
      generatedData?.garment != null &&
      generatedData?.reference != null &&
      generatedData?.styling_description?.pairing != null &&
      generatedData?.styling_description?.pairing !== ''
    );
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handlePrevious = () => {
    setActiveStep((prevStep) => prevStep - 1);
  };

  const handleGenerate = async () => {
    setIsLoading(true);
    try {
      const response = await initCreation(alleUserId, id, generatedData);
      setGeneration(response.data);
      setIsGenerated(true);
    } catch (error) {
      console.error('Error initializing creation:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getConfig(alleUserId).then((response) => {
      setConfig(response.data);
    });
  }, []);

  const CurrentStepComponent = stepComponents[activeStep];

  if (isGenerated) {
    return <Prompt generationData={generation} />;
  }

  return (
    <Container sx={{ mt: 4 }} maxWidth="xl">
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <Paper elevation={0} sx={{ p: 3 }}>
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                <Step key={step.label}>
                  <StepLabel
                    icon={
                      index < activeStep ? (
                        <CheckCircleOutlineIcon color="success" />
                      ) : (
                        <Box
                          sx={{
                            width: 24,
                            height: 24,
                            border: '2px solid',
                            borderColor: 'text.disabled',
                            borderRadius: '50%',
                          }}
                        />
                      )
                    }
                  >
                    <Typography variant="subtitle2">{step.label}</Typography>
                    <Typography variant="caption" color="text.secondary">
                      {step.description}
                    </Typography>
                  </StepLabel>
                </Step>
              ))}
            </Stepper>
          </Paper>
        </Grid>
        <Grid item xs={12} md={9}>
          <Paper elevation={0} sx={{ p: 3 }}>
            <CurrentStepComponent
              setGeneratedData={setGeneratedData}
              referenceConfig={config?.reference_images || []}
              garmentConfig={config?.base_images || []}
              garment={generatedData?.garment}
              reference={generatedData?.reference}
              description={generatedData?.styling_description}
              generationData={generatedData}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 3 }}>
              <Button
                variant="outlined"
                color="primary"
                onClick={handlePrevious}
                disabled={activeStep === 0 || isLoading}
              >
                Previous
              </Button>
              {activeStep === steps.length - 1 && isLoading ? (
                <Button
                  variant="contained"
                  color="primary"
                  disabled={true}
                  startIcon={<CircularProgress size={20} color="inherit" />}
                >
                  Generating Final Prompt...
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={activeStep === steps.length - 1 ? handleGenerate : handleNext}
                  disabled={
                    (activeStep === steps.length - 1 ? !enableFinish() : !enableNext()) || isLoading
                  }
                >
                  Next
                </Button>
              )}
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  );
}
