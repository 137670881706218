import React, { useState } from 'react';
import { Box, Modal } from '@mui/material';
import { styled } from '@mui/system';

const ImagePreview = styled('img')({
  width: '100%',
  height: 'auto',
  objectFit: 'contain',
  maxHeight: '50vh',
  cursor: 'pointer',
});

const ModalImage = styled('img')({
  maxWidth: '90vw',
  maxHeight: '90vh',
  objectFit: 'contain',
});

export default function ImageWithModal({ src, alt }) {
  const [modalOpen, setModalOpen] = useState(false);

  const handleImageClick = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  return (
    <>
      <ImagePreview
        src={src}
        alt={alt}
        onClick={handleImageClick}
      />
      <Modal
        open={modalOpen}
        onClose={handleCloseModal}
        aria-labelledby="image-modal"
        aria-describedby="larger view of the image"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <ModalImage src={src} alt={alt} />
        </Box>
      </Modal>
    </>
  );
}
