import { Box, Card, CardMedia, Checkbox, Grid, Modal, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';

const ModalImage = styled('img')({
  maxWidth: '90vw',
  maxHeight: '90vh',
  objectFit: 'contain',
});

const ImageGallery = ({ images, updateSelected, selected, disabled }) => {
  const [selectedImages, setSelectedImages] = useState({});
  const [open, setOpen] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);

  const handleSelectImage = (payload) => {
    setSelectedImages((prev) => {
      const selected = {
        [payload]: !prev[payload],
      };

      const selectedPayloads = Object.keys(selected).filter((key) => selected[key]);
      updateSelected(selectedPayloads);
      return selected;
    });
  };

  const handleOpen = (imageObj) => {
    setCurrentImage(imageObj?.preview_image_url);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setCurrentImage(null);
  };

  useEffect(() => {
    const selectedObject = selected.reduce((acc, imageId) => {
      acc[imageId] = true;
      return acc;
    }, {});
    setSelectedImages(selectedObject);
  }, [selected]);

  return (
    <Box display="flex" flexDirection="column" alignItems="flex-start" gap={2} width={'100%'}>
      <Typography variant="h6">Generated Images</Typography>
      <Grid container spacing={2}>
        {images.map((obj, index) => (
          <Grid item xs={12} sm={6} md={2} key={index}>
            <Card style={{ position: 'relative' }}>
              <CardMedia
                component="img"
                image={obj?.preview_image_url}
                alt={`image-${index}`}
                height="100%"
                style={{ cursor: 'pointer' }}
                onClick={() => handleOpen(obj)}
              />
              <Box
                style={{
                  position: 'absolute',
                  top: 10,
                  right: 10,
                  backgroundColor: 'rgba(255, 255, 255, 0.7)',
                  borderRadius: '50%',
                  padding: '5px',
                }}
              >
                <Checkbox
                  checked={!!selectedImages[obj?.generated_image_payload]}
                  onChange={() => handleSelectImage(obj?.generated_image_payload)}
                  disabled={disabled}
                />
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="image-modal"
        aria-describedby="larger view of the image"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
          }}
        >
          <ModalImage src={currentImage} alt="Fullscreen" />
        </Box>
      </Modal>
    </Box>
  );
};

export default ImageGallery;
