import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import { Tabs, Tab, Box } from '@mui/material';
import GenerationFlow from './generation_flow.jsx';
import MyImages from './my_images.jsx';
import Dashboard from './dashboard.jsx';

const StyledTabs = styled(Tabs)({
  '& .MuiTabs-indicator': {
    display: 'none',
  },
  minHeight: '48px',
});

const StyledTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontWeight: 500,
  fontSize: '14px',
  marginRight: theme.spacing(1),
  color: 'rgba(0, 0, 0, 0.7)',
  padding: '6px 16px',
  minHeight: '36px',
  '&.Mui-selected': {
    color: '#fff',
    backgroundColor: theme.palette.primary.main,
    borderRadius: '9999px',
  },
  '&:hover': {
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '9999px',
    opacity: 1,
  },
}));

export default function Home({ id }) {
  const [activeTab, setActiveTab] = useState('generate');

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  return (
    <div className="home-container">
      <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 2, marginTop: 2 }}>
        <StyledTabs value={activeTab} onChange={handleTabChange} aria-label="home tabs">
          <StyledTab label="Generate" value="generate" />
          <StyledTab label="My images" value="myGenerations" />
          <StyledTab label="Dashboard" value="dashboard" />
        </StyledTabs>
      </Box>

      <div className="tab-content">
        {activeTab === 'generate' && (
          <div className="generate-tab">
            <GenerationFlow id={id} />
            {/* Add content for Generate tab */}
          </div>
        )}
        {activeTab === 'myGenerations' && (
          <div className="my-generations-tab">
            <MyImages />
            {/* Add content for My Generations tab */}
          </div>
        )}
        {activeTab === 'dashboard' && (
          <div className="dashboard-tab">
            <Dashboard />
            {/* Add content for Dashboard tab */}
          </div>
        )}
      </div>
    </div>
  );
}
