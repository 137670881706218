import React, { useState, useEffect } from 'react';
import { TextField, Typography, Paper, Button, Box, Grid, Alert } from '@mui/material';
import { useNavigate, createSearchParams } from 'react-router-dom';
import CheckIcon from '@mui/icons-material/Check';
import ImageWithModal from './image.jsx';
import { v4 as uuidv4 } from 'uuid';
import { generateImage } from '../apis/storefront';
import { useAuth } from '../contexts/authContext';

const Prompt = ({ generationData }) => {
  const [prompt, setPrompt] = useState(generationData?.prompt || '');
  const [isGenerating, setIsGenerating] = useState(false);
  const navigate = useNavigate();

  const { alleUserId } = useAuth();

  useEffect(() => {
    setPrompt(generationData?.prompt);
  }, [generationData]);

  const handlePromptChange = (e) => {
    setPrompt(e.target.value);
  };

  const handleGenerate = () => {
    setIsGenerating(true);
    generateImage(alleUserId, generationData.public_id, prompt)
      .then((response) => {
        setIsGenerating(true);
      })
      .catch((error) => {
        console.error('Error generating image:', error);
        setIsGenerating(false);
      });
  };

  const handleRedirectToApp = () => {
    const newId = uuidv4();
    navigate(
      {
        pathname: '/app',
        search: createSearchParams({ id: newId }).toString(),
      },
      { replace: true },
    );
  };

  if (isGenerating) {
    return (
      <Box sx={{ textAlign: 'center', mt: 4 }}>
        <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          Your image is being generated...Please check back in My Photos in a few minutes.
        </Alert>
        <Button variant="contained" onClick={handleRedirectToApp} sx={{ mt: 2 }}>
          Create New Image
        </Button>
      </Box>
    );
  }

  return (
    <>
      <Grid container spacing={2} sx={{ marginBottom: 4 }}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Garment Image
            </Typography>
            <ImageWithModal src={generationData?.garment_image?.url} alt="Garment" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Reference Image
            </Typography>
            <ImageWithModal src={generationData?.reference_image?.url} alt="Reference" />
          </Paper>
        </Grid>
      </Grid>
      <Grid container sx={{ display: 'flex', flexDirection: 'column', gap: 2, margin: 'auto' }}>
        <Grid item xs={12}>
          <TextField
            value={prompt}
            onChange={handlePromptChange}
            placeholder="Enter your prompt here..."
            multiline
            rows={10}
            variant="outlined"
            fullWidth
            label="Prompt"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Button variant="contained" onClick={handleGenerate} disabled={!prompt.trim()}>
            Generate Image
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default Prompt;
