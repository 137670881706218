import React, { useState, useEffect } from 'react';
import { Typography, Grid, Box } from '@mui/material';
import { styled } from '@mui/system';

const ImageItem = styled('img')(({ theme, selected, anySelected }) => ({
  width: '100%',
  height: 'auto',
  border: selected ? `3px solid red` : 'none',
  cursor: 'pointer',
  opacity: anySelected ? (selected ? 1 : 0.5) : 1,
  '&:hover': {
    opacity: 0.8,
  },
}));

export default function ReferenceImage({ setGeneratedData, referenceConfig, reference }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);

  const handleImageSelect = (id) => {
    setSelectedImage((prevSelected) => (prevSelected === id ? null : id));
  };

  useEffect(() => {
    if (referenceConfig) {
      setImageUrls(referenceConfig);
      if (reference) {
        let selectedReferenceId = referenceConfig.find((image) => image?.id === reference?.id)?.id;
        if (selectedReferenceId) {
          setSelectedImage(selectedReferenceId);
        }
      }
    }
  }, [referenceConfig]);

  useEffect(() => {
    setGeneratedData((prevData) => ({
      ...prevData,
      reference:
        selectedImage === null ? null : imageUrls.find((image) => image?.id === selectedImage),
    }));
  }, [selectedImage]);

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Select Reference Image:
      </Typography>
      <Box>
        <Grid container spacing={2} sx={{ mt: 2, height: '75vh', overflowY: 'auto' }}>
          {imageUrls.map((image) => (
            <Grid item xs={4} sm={3} key={image.id}>
              <ImageItem
                src={image.url}
                alt={`Image ${image.id}`}
                selected={selectedImage === image.id}
                anySelected={selectedImage !== null}
                onClick={() => handleImageSelect(image.id)}
                style={{ width: '15vw' }}
              />
            </Grid>
          ))}
        </Grid>
      </Box>
    </div>
  );
}
