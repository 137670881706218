import axios from 'axios';
import jwtEncode from 'jwt-encode';

const baseUrl = process.env.REACT_APP_API_BASE_URL || 'http://sandbox-api.heyalle.com';
const jwtSecret = process.env.REACT_APP_JWT_SECRET || 'afcd8e5e-e88d-410f-ba44-1368cf2cfb7d';

if (!jwtSecret) {
  console.error('JWT secret is not set. Please set the REACT_APP_JWT_SECRET environment variable.');
}

let headers = {
  'Content-Type': 'application/json',
  'alle-client': 'web',
  'alle-client-version-code': '440',
  Accept: 'application/json',
};

export function signJsonWithJwtToken(payload) {
  try {
    const token = jwtEncode(payload, jwtSecret);
    return token;
  } catch (error) {
    console.error('Error signing JSON with JWT token:', error);
    throw error;
  }
}

export function login(firebaseToken) {
  let url = baseUrl + '/api/v1/admin/login';
  const body = {
    firebase: {
      id_token: firebaseToken,
    },
  };

  return axios.post(url, body, {
    headers: {
      'Content-Type': 'application/json',
      'alle-client': 'web',
      'alle-client-version-code': '440',
      Accept: 'application/json',
    },
  });
}

export async function getConfig(alleUserId) {
  const tokenPayload = {
    user_id: alleUserId,
  };

  const token = await signJsonWithJwtToken(tokenPayload);

  let url = baseUrl + '/api/v1/creator-tool/config';
  return axios.get(url, {
    headers: {
      ...headers,
      'alle-token': token,
    },
  });
}

export function getCreation(alleUserId, creationId) {
  let url =
    baseUrl +
    '/internal/api/v1/creator-tool/status?user_public_id=' +
    alleUserId +
    '&public_id=' +
    creationId;
  return axios.get(url);
}

export async function initCreation(alleUserId, id, generationData) {
  console.log('init creation', generationData);

  const tokenPayload = {
    user_id: alleUserId,
  };

  const token = await signJsonWithJwtToken(tokenPayload);

  let url = baseUrl + '/api/v2/creator-tool/init?user_public_id=' + alleUserId;

  let body = {
    public_id: id,
    garment_image_payload: generationData?.garment?.payload,
    reference_image_payload: generationData?.reference?.payload,
    style_description: generationData?.styling_description,
  };

  console.log('init creation body', body);
  return axios.post(url, body, {
    headers: {
      ...headers,
      'alle-token': token,
    },
  });
}

export async function getGenerations(alleUserId, date) {
  const tokenPayload = {
    user_id: alleUserId,
  };

  const token = await signJsonWithJwtToken(tokenPayload);

  const url = `${baseUrl}/api/v1/creator-tool/feed?date=${date}`;

  const reqHeaders = {
    ...headers,
    'alle-token': token,
  };

  return axios.get(url, { headers: reqHeaders });
}

export async function getGeneration(alleUserId, generationId) {
  const tokenPayload = {
    user_id: alleUserId,
  };

  const token = await signJsonWithJwtToken(tokenPayload);

  const url = `${baseUrl}/api/v1/creator-tool/generated?public_id=${generationId}`;

  const reqHeaders = {
    ...headers,
    'alle-token': token,
  };

  return axios.get(url, { headers: reqHeaders });
}

export function initImageGeneration(alleUserId, email, publicId, avatarDescription, category) {
  const url = `${baseUrl}/internal/api/v1/creator-tool/generate?user_public_id=${alleUserId}`;

  const reqBody = {
    public_id: publicId,
    avatar_description: avatarDescription,
    generation_stage: 0,
    category: category,
  };

  console.log('Avatar description:', avatarDescription);

  return axios.post(url, reqBody, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}

export async function submitImages(alleUserId, publicId, payloads) {
  const tokenPayload = {
    user_id: alleUserId,
  };

  const token = await signJsonWithJwtToken(tokenPayload);

  const url = `${baseUrl}/api/v1/creator-tool/submit`;

  const reqBody = {
    public_id: publicId,
    payloads: payloads,
  };

  return axios.post(url, reqBody, {
    headers: {
      ...headers,
      'alle-token': token,
    },
  });
}

export async function generateImage(alleUserId, publicId, finalPrompt) {
  const url = `${baseUrl}/api/v2/creator-tool/generate?user_public_id=${alleUserId}`;

  const tokenPayload = {
    user_id: alleUserId,
  };

  const token = await signJsonWithJwtToken(tokenPayload);

  const reqBody = {
    public_id: publicId,
    prompt: finalPrompt,
  };

  console.log('reqBody', reqBody);
  return axios.post(url, reqBody, {
    headers: {
      ...headers,
      'alle-token': token,
    },
  });
}

export async function getUserDashboard(alleUserId) {
  const tokenPayload = {
    user_id: alleUserId,
  };

  const token = await signJsonWithJwtToken(tokenPayload);

  const url = `${baseUrl}/api/v1/creator-tool/user/dashboard`;

  const reqHeaders = {
    ...headers,
    'alle-token': token,
  };

  return axios.get(url, { headers: reqHeaders });
}
