import { Button, Container, Grid, Typography } from '@mui/material';
import 'firebase/auth';
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { doSignInWithGoogle } from '../firebase/auth';
import GoogleIcon from '@mui/icons-material/Google';

function Login() {
  const { userLoggedIn } = useAuth();

  const handleGoogleSignIn = async () => {
    doSignInWithGoogle().catch((error) => {
      window.alert(error.message);
      console.error(error);
    });
  };

  return userLoggedIn ? (
    <Navigate to={'/app'} replace={true} />
  ) : (
    <Container>
      <Container style={{ height: '25vh' }}></Container>
      <Container
        maxWidth="sm"
        style={{
          border: '0.5px solid black',
          padding: '1vw',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid container spacing={2} style={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item xs={12} sm={12}>
            <h1>Alle Creator Tool</h1>
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              onClick={handleGoogleSignIn}
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <Typography variant="body1" style={{ marginLeft: '8px', marginRight: '8px' }}>
                Sign in with Google
              </Typography>
              <GoogleIcon />
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}

export default Login;
