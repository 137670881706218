import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, Paper, Grid, Container, Alert } from '@mui/material';
import ImageWithModal from './image.jsx';
import { Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useLocation, useNavigate } from 'react-router-dom';
import { getGeneration, submitImages } from '../apis/storefront';
import { useAuth } from '../contexts/authContext';
import ImageGallery from './image_gallery.jsx';
import Prompt from './final_prompt.jsx';
import * as constants from './constants.jsx';

export default function Generated() {
  const location = useLocation();
  const navigate = useNavigate();

  const [generatedData, setGeneratedData] = useState(null);
  const [selectedPayloads, setSelectedPayloads] = useState([]);
  const [isPromptGeneratedState, setIsPromptGeneratedState] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false); // New state for showing Prompt

  const { userLoggedIn, alleUserId } = useAuth();

  useEffect(() => {
    if (!userLoggedIn) {
      navigate('/login');
      return;
    }

    const queryParams = new URLSearchParams(location.search);
    const queryParamValue = queryParams.get('id');

    if (queryParamValue) {
      getGeneration(alleUserId, queryParamValue).then((response) => {
        console.log('response', response);
        setGeneratedData(response.data);
        if (response.data.generation_status === constants.CreatorToolStatusPromptGenerated) {
          setIsPromptGeneratedState(true);
        }
        if (
          response.data.generation_status === constants.CreatorToolStatusImagesSubmitted ||
          response.data.generation_status === constants.CreatorToolStatusImagesApproved ||
          response.data.generation_status === constants.CreatorToolStatusImagesRejected
        ) {
          setIsSubmitted(true);
          let submitted = [];
          if (response.data?.generated_images) {
            for (const image of response.data.generated_images) {
              if (image?.selected) {
                submitted.push(image?.generated_image_payload);
              }
            }
            setSelectedPayloads(submitted);
          }
        }
      });
    }
  }, [userLoggedIn, navigate, location.search, alleUserId]);

  const handleSubmit = () => {
    submitImages(alleUserId, generatedData.public_id, selectedPayloads)
      .then((response) => {
        if (response.status === 200) {
          setIsSubmitted(true);
        }
      })
      .catch((error) => {
        console.error('Error submitting images:', error);
      });
  };

  const handleRetry = () => {
    setShowPrompt(true); // Set state to show Prompt component
  };

  if (showPrompt) {
    return (
      <Container sx={{ mt: 2, mb: 2 }} maxWidth="xl">
        <Prompt generationData={generatedData} />
      </Container>
    );
  }

  return (
    <>
      {!isPromptGeneratedState ? (
        <Box sx={{ flexGrow: 1, p: 4 }}>
          <Container sx={{ mt: 2, mb: 2 }} maxWidth="xl">
            <ImageGallery
              images={generatedData?.generated_images || []}
              selected={selectedPayloads || []}
              updateSelected={setSelectedPayloads}
              disabled={isSubmitted}
            />
          </Container>
          <Container sx={{ mt: 2, mb: 2 }} maxWidth="xl">
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Typography>Generation Input</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Grid container spacing={3}>
                  {/* Garment Image */}
                  <Grid item xs={12} sm={4}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        Garment Image
                      </Typography>
                      <ImageWithModal src={generatedData?.garment_image?.url} alt="Garment" />
                      <Typography variant="caption" gutterBottom>
                        {generatedData?.garment?.meta?.product_category}
                      </Typography>
                    </Paper>
                  </Grid>

                  {/* Reference Image */}
                  <Grid item xs={12} sm={4}>
                    <Paper elevation={3} sx={{ p: 2 }}>
                      <Typography variant="h6" gutterBottom>
                        Reference Image
                      </Typography>
                      <ImageWithModal src={generatedData?.reference_image?.url} alt="Reference" />
                    </Paper>
                  </Grid>

                  {/* Styling Description */}
                  <Grid item xs={12} sm={4}>
                    <Paper elevation={3} sx={{ p: 2, height: '100%', overflow: 'auto' }}>
                      <Typography variant="h6" gutterBottom>
                        Styling Description
                      </Typography>
                      <Typography variant="body1">{generatedData?.prompt}</Typography>
                    </Paper>
                  </Grid>
                </Grid>
              </AccordionDetails>
            </Accordion>
          </Container>
          <Grid container justifyContent="center" sx={{ mt: 4 }}>
            {!isSubmitted ? (
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                sx={{ mr: 2 }}
                disabled={selectedPayloads.length === 0}
              >
                Submit
              </Button>
            ) : (
              <Alert severity="success">Images submitted successfully!</Alert>
            )}
            {!isSubmitted &&
              generatedData?.generation_status === constants.CreatorToolStatusImagesGenerated && (
                <Button variant="outlined" onClick={handleRetry}>
                  Retry
                </Button>
              )}
          </Grid>
        </Box>
      ) : (
        <Container sx={{ mt: 2, mb: 2 }} maxWidth="xl">
          <Prompt generationData={generatedData} />
        </Container>
      )}
    </>
  );
}
