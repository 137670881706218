import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Typography, Grid, Box, Link, IconButton, Pagination } from '@mui/material';
import { styled } from '@mui/system';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';

// Simplified ImageItem component without caching logic
const ImageItem = styled('img')(({ theme, selected, anySelected }) => ({
  width: '100%',
  height: 'auto',
  border: selected ? `3px solid red` : 'none',
  cursor: 'pointer',
  opacity: anySelected ? (selected ? 1 : 0.5) : 1,
  '&:hover': {
    opacity: 0.8,
  },
}));

// Simplified CachedImageItem component without caching logic
const CachedImageItem = React.memo(
  ({ image, selected, anySelected, onClick }) => {
    return (
      <ImageItem
        src={image.url}
        alt={`Image ${image.id}`}
        selected={selected}
        anySelected={anySelected}
        onClick={onClick}
        style={{ width: '15vw' }}
      />
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.selected === nextProps.selected &&
      prevProps.anySelected === nextProps.anySelected &&
      prevProps.image.id === nextProps.image.id
    );
  },
);

export default function GarmentImage({ setGeneratedData, garment, garmentConfig }) {
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 20;

  const imageCache = useRef({});
  const elementCache = useRef({});

  const handleImageSelect = (id) => {
    setSelectedImage((prevSelected) => (prevSelected === id ? null : id));
  };

  useEffect(() => {
    setImageUrls(garmentConfig);
    if (garment) {
      let selectedGarmentId = garmentConfig.find((image) => image?.id === garment?.id)?.id;
      if (selectedGarmentId) {
        setSelectedImage(selectedGarmentId);
      }
    }
  }, [garmentConfig]);

  useEffect(() => {
    setGeneratedData((prevData) => ({
      ...prevData,
      garment:
        selectedImage === null ? null : imageUrls.find((image) => image?.id === selectedImage),
    }));
  }, [selectedImage]);

  const getCachedImage = (image) => {
    // Only create new CachedImageItem if it doesn't exist in elementCache
    if (!elementCache.current[image.id]) {
      elementCache.current[image.id] = (
        <CachedImageItem
          image={image}
          selected={selectedImage === image.id}
          anySelected={selectedImage !== null}
          onClick={() => handleImageSelect(image.id)}
        />
      );
    }

    // Update the props of cached element without recreating it
    return React.cloneElement(elementCache.current[image.id], {
      selected: selectedImage === image.id,
      anySelected: selectedImage !== null,
    });
  };

  // Preload images for current and adjacent pages
  useEffect(() => {
    const preloadImage = (url) => {
      if (!imageCache.current[url]) {
        imageCache.current[url] = new Image();
        imageCache.current[url].src = url;
      }
    };

    const startIndex = Math.max(0, (page - 1) * itemsPerPage - itemsPerPage);
    const endIndex = Math.min(imageUrls.length, (page + 1) * itemsPerPage + itemsPerPage);
    const imagesToPreload = imageUrls.slice(startIndex, endIndex);

    imagesToPreload.forEach((image) => {
      preloadImage(image.url);
    });
  }, [page, imageUrls]);

  // Clear cache when garmentConfig changes
  useEffect(() => {
    elementCache.current = {};
    imageCache.current = {};
  }, [garmentConfig]);

  // Memoize the image items
  const cachedImageItems = useMemo(() => {
    const startIndex = (page - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const paginatedUrls = imageUrls.slice(startIndex, endIndex);

    return paginatedUrls.map((image) => (
      <Grid item xs={4} sm={3} key={image.id}>
        {getCachedImage(image)}
        {image?.meta?.trigger_word && (
          <Typography variant="caption" display="block" gutterBottom>
            Trigger Word: {image.meta.trigger_word}
          </Typography>
        )}
        {image?.meta?.product_category && (
          <Typography variant="caption" display="block" gutterBottom>
            Product Category: {image.meta.product_category}
          </Typography>
        )}
        {image?.meta?.brand && (
          <Typography variant="caption" display="block" gutterBottom>
            Brand: {image.meta.brand}
          </Typography>
        )}
        {image?.meta?.price && (
          <Typography variant="caption" display="block" gutterBottom>
            Price: {image.meta.price}
          </Typography>
        )}
        {image?.meta?.product_url && (
          <Typography variant="caption" display="block" gutterBottom>
            Product Url:
            <Link href={image.meta.product_url} target="_blank" rel="noopener">
              <IconButton size="small" style={{ fontSize: '1rem', padding: '2px' }}>
                <OpenInNewIcon fontSize="inherit" />
              </IconButton>
            </Link>
          </Typography>
        )}
        {image?.meta?.total_submissions && (
          <Typography variant="caption" display="block" gutterBottom>
            Total Submissions: {image.meta.total_submissions}
          </Typography>
        )}
      </Grid>
    ));
  }, [imageUrls, selectedImage, handleImageSelect, page]);

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  return (
    <div>
      <Typography variant="h6" gutterBottom>
        Select Product:
      </Typography>
      <Box>
        <Grid container spacing={2}>
          {cachedImageItems}
        </Grid>
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2, mb: 2 }}>
          <Pagination
            count={Math.ceil(imageUrls.length / itemsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Box>
      </Box>
    </div>
  );
}
