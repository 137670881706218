import React from 'react';
import { Typography, Paper, Grid } from '@mui/material';
import ImageWithModal from './image.jsx';

export default function Verify({ generationData }) {
  return (
    <>
      <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
        Please verify the input and proceed
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Garment Image
            </Typography>
            <ImageWithModal src={generationData?.garment?.url} alt="Garment" />
            <Typography variant="caption" gutterBottom>
              {generationData?.garment?.meta?.product_category}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Reference Image
            </Typography>
            <ImageWithModal src={generationData?.reference?.url} alt="Reference" />
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Styling Description
            </Typography>
            {Object.entries(generationData?.styling_description || {}).map(([key, value]) => (
              <Typography variant="body1" key={key}>
                {`${key.charAt(0).toUpperCase() + key.slice(1)}: ${value}`}
              </Typography>
            ))}
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}
