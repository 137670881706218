import React, { useContext, useEffect, useState } from 'react';

import { onAuthStateChanged } from 'firebase/auth';
import { auth } from '../../firebase/firebase';
import { login } from '../../apis/storefront';

const AuthContext = React.createContext();

export function useAuth() {
  return useContext(AuthContext);
}

const alle_user_id_map = {
  LA6zag5bZoY3KLOvXTY8aw4tiRr1: 'e54f66bd-6928-497c-9c53-53abe00bd641',
  FkAENzAceTZxkPgd40uWqzBYckt2: 'c9ebc3d1-a8c0-4566-a18d-fcfdcb83d7ee',
  gc72y9Gs34gaHLjY6WVL9WQtC183: 'afeef753-27e8-43a6-9f7a-af8817bfa7d6',
  G8J4OSRhbaXYz6RzZ4XM1QB6cz42: 'ccfccdc5-341c-435a-91fb-46a6828feec5',
  hnzqlSoyJIQEeEsXcH8grJ4U5OF3: 'fd7d10ad-7a6a-48f6-ae51-2dd92850f771',
  yO9f4bXrldPhIBIs1pgvZIqaQzM2: '6a0bff6a-505b-47c2-bf45-ff6af375fe3c',
  NPGprsq5bsZvwHhCxiXqTH1UqgE2: '77a474c9-5fa0-406d-8eb8-aac5519a2c5b',
  OqvyH4BFYrWLNgcvmIpHjOnUnjb2: '8f0cfb23-64b0-45db-af88-060101ab8b85',
  V820kRnYzFRmmyXEQQ1QJkjADR72: 'fe9f80e7-c0de-416d-ba7e-c304787f4cb2',
  D0gNFB6bgtX4rq75e9HSThtsTVq2: '03548dc6-5375-48ba-99d2-d47d6ce133aa',
  JKRSQQNwkohF7n4YwN1VB5TxRR02: 'bc359a15-88ab-4fef-9a4e-8a53ac9615c8',
  pCPKD6mRCDcIcy9NKq45WblTYdD3: '117ce506-2c77-4ede-9201-67b2e6308419',
  UF32QqczQtYv3Bnhyxp8h43D9zr2: 'b8025c8a-992e-44b8-8fde-2488e690d969',
  odo7p6bcQvfHjPH64KCfWxeg7bi2: 'fad85bfe-dbce-4c69-b141-3a360db05a25',
  pMfppQbPJOPDRqXkMoDj1UlHyMg1: 'a3fe0422-bacb-4b77-bdd3-24b67ca78f8d',
  QQZxrgvz1pNXtsb8HMuY8AjjVWl2: '20338243-e320-4541-9703-b86335d216a4',
  uA7XZ04HRIPY1R8w7MtJ5SvF14f2: '4bca7faf-77f4-43f2-8632-727c832d6723',
  YfGnFqPJsdZ1qS22tgC9QdjdUQA3: '566c503f-7d07-4489-a207-1df34ba00fb1',
  stKsXP104PVUToCqgxHDnMUdBPH3: '642d30b1-4d01-468d-a3dc-abaddf60ef0c',
  xzfQihSpOpcuihL7flRY2vVwVHw2: '2bd71887-794e-4fb6-acd1-fc1089086d88',
  '80kZCHIIwzOuIi5R1mk7TFO0bEm1': '01aa1e5a-ab56-4e37-bc1e-c7d7cb54a373',
  V2uSil3HijV7fOxcQlYUQOccJCI3: '3fe7366b-b836-4ea0-84ed-f3f12df9e1ad',
  LgzSx7Y99HQg0bqjinbFlRxDHEl1: '85e11e8e-0fd0-439e-bfb3-14e106a8bc70',
  SoaVeRZuWrZRFDK7KcE9lhfdm063: 'c5ec9f50-3fb1-47bc-b705-be372861b897',
  zOA5IVTTr5aHzcGk7uRSIdGDLDm2: 'b572ccac-3381-41fb-b560-d86a0403efda',
  UDqJWEMhAJYuV8rDw4foQyIaWP82: 'e36f8058-c80a-43a9-99d8-9518e22140f6',
  aF0IigxE04c4CQnK2mIPG8Ze8jc2: 'c3628d96-1596-4aa2-9152-d0596d4c18c5',
  TOD7l5QhCjPL1YTGZBVG6ACJKT73: '7f77be05-c70b-4252-89e1-0516d090aade',
  oUCeJg32rifpjdcxq9VMeomMVNG2: '64116a9a-4c6f-4129-8c33-d98f078d23d8',
  OP8ziiOR5xgtikKXC4PVmpd8cGD3: 'b7321f63-135b-442f-82de-73bdaf98a991',
  E1ZfFS8bwlhiGFNWjEldHKVcUGL2: '7d7bab55-8a89-45b9-a846-7c66bf43c732',
  HUqPu2kdG4YB0HU8NxTLTnXx8BF2: 'e54f66bd-6928-497c-9c53-53abe00bd641',
  V3Mh880YmsZczMlK4cpuBLvbZGv2: '7baefc54-fde2-4949-ab9d-e18507e72f44',
  AqgK0qVyd5UtLKpOHAcG8qyvOIf1: 'e6ec9da7-e240-423e-abc0-ecb732c3264c',
  MlAwGIOxHjUIjfisrki9jRkcZ6O2: '270950a8-f7d0-4d75-b77b-201cad321e5f',
  bZUiWF6LLVfF3j9eeaj61msB5Ou2: '42dd6d88-d556-4dbc-87b4-7ee2526242a7',
  Q2q2XXEvhig1ixIS0Ss7qfwScPA2: 'acc461f3-5baf-4ac6-84ea-157b2e9a6143',
  '55HfoWHl1SYUs9vWGffx7Hh2ZRN2': '846bc624-834d-4bc0-89dc-a2af3a3d058b',
  '0IW4H1KxCjPd9hux0n1GAVcyBX12': '48408c7c-f896-469a-94ff-39160a92ffdc',
  JBuMazvsA5VGWeTbOdKj6J0PCJo1: '8b6a484c-1ecc-4821-b600-5dc3107f1163',
  mujKBG5qZuWmfRU1I4WaLgjyeuC2: 'a8374adf-6451-4a0a-97f4-1d3e949fed02',
  sCceY8oLpdWLwk4VeZRqBBBj7lZ2: 'd61f54c9-da18-46f9-82f5-12ebb25f19fd',
  PLQVxJPCymZK40sspXvlucqjVd33: '3379f09b-9eb8-4ac2-aa21-4f2f8d52e91b',
  '41BPQYnSTyd8hBFHDzfhlIz46ul1': 'c1cf7192-fb95-4f77-8fba-d226d6ba6f35',
  aPR5S17KjYNMVCVOshUhfybFmQ42: 'd042e7d8-6ea0-4280-8df3-e47a14f243e8',
  edMQubetPMPYce8mQUeLt7EF60c2: '86cf0dc0-b09a-43a3-97a0-86f107ad8af1',
  h8WzYGG3QsQx2o8jWDA3wB1l5vJ2: 'e9525851-c3db-4f06-97ea-a705f41c2a26',
  LA804MMe6ugzYV0p2VZPcCdFmDM2: '702750ac-45f1-4da5-bc46-f1b054a76d05',
};

export function AuthProvider({ children }) {
  const [currentUser, setCurrentUser] = useState(null);
  const [userLoggedIn, setUserLoggedIn] = useState(false);
  const [alleUserId, setAlleUserId] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, initializeUser);
    return unsubscribe;
  }, []);

  async function initializeUser(user) {
    if (user) {
      const response = await login(user.accessToken);
      setCurrentUser(user);
      setAlleUserId(response.data.user.public_id);
      setUserLoggedIn(true);
    } else {
      setCurrentUser(null);
      setAlleUserId('');
      setUserLoggedIn(false);
    }
    setLoading(false);
  }

  const value = {
    currentUser,
    userLoggedIn,
    alleUserId,
    loading,
  };

  return <AuthContext.Provider value={value}>{!loading && children}</AuthContext.Provider>;
}
