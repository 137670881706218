import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import CreatorApp from './components/app';
import Login from './components/login';
import Navbar from './components/navbar';
import { AuthProvider } from './contexts/authContext';
// import ProductLinks from './components/products';
import ThemeComponent from './theme/theme';
import Generated from './components/generated';

function App() {
  return (
    <ThemeComponent>
      <AuthProvider>
        <Router basename="/tR4pvABzrWhmDCi">
          <Navbar></Navbar>
          <Routes>
            <Route exact path="/" element={<Login />} />
            <Route exact path="/app" element={<CreatorApp />} />
            <Route exact path="/generated" element={<Generated />} />
            <Route exact path="/login" element={<Login />} />
          </Routes>
        </Router>
      </AuthProvider>
    </ThemeComponent>
  );
}

export default App;
