import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyCyVdJZCzi267ch2HTpw6CN4tesVoDV-I4',
  authDomain: 'internal-chat-ui-auth.firebaseapp.com',
  projectId: 'internal-chat-ui-auth',
  storageBucket: 'internal-chat-ui-auth.appspot.com',
  messagingSenderId: '902440937347',
  appId: '1:902440937347:web:fcee74a8a6d3fae95f38b6',
  measurementId: 'G-SE7WMTCPPF',
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { app, auth };
