import React, { useState, useEffect } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Chip,
  IconButton,
  Skeleton,
  Tooltip,
  TextField,
} from '@mui/material';
import { OpenInNew as OpenInNewIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { getGenerations } from '../apis/storefront';
import { useAuth } from '../contexts/authContext';
import * as constants from './constants.jsx';

const getChipBackgroundColor = (status) => {
  switch (status) {
    case constants.CreatorToolStatusPromptGenerated:
      return '#ADD8E6';
    case constants.CreatorToolStatusGeneratingImage:
      return 'grey';
    case constants.CreatorToolStatusImagesGenerated:
      return '#90EE90';
    case constants.CreatorToolStatusImagesSubmitted:
      return '#FFD700';
    case constants.CreatorToolStatusImagesApproved:
      return '#32CD32';
    case constants.CreatorToolStatusImagesRejected:
      return '#FF6347';
    case constants.CreatorToolStatusFailed:
      return '#B22222';
    default:
      return 'default';
  }
};

export default function MyImages() {
  const [generations, setGenerations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(dayjs());
  const { alleUserId } = useAuth();
  const [isPolling, setIsPolling] = useState(false);

  useEffect(() => {
    let intervalId;

    const fetchGenerations = () => {
      if (alleUserId) {
        const formattedDate = selectedDate.format('YYYY-MM-DD');
        getGenerations(alleUserId, formattedDate)
          .then((res) => {
            setGenerations(res.data.generations);

            // If is_processing is true, set up polling
            if (res.data.is_processing && !isPolling) {
              setIsPolling(true);
              intervalId = setInterval(() => {
                getGenerations(alleUserId, formattedDate)
                  .then((pollRes) => {
                    setGenerations(pollRes.data.generations);

                    // Stop polling if is_processing becomes false
                    if (!pollRes.data.is_processing) {
                      clearInterval(intervalId);
                      setIsPolling(false);
                    }
                  })
                  .catch((pollError) => {
                    console.error('Error polling generations:', pollError);
                    clearInterval(intervalId);
                    setIsPolling(false);
                  });
              }, 5000);
            }
          })
          .catch((error) => {
            console.error('Error fetching generations:', error);
          });
      }
    };

    fetchGenerations();

    // Clean up function to clear the interval when the component unmounts or dependencies change
    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [alleUserId, selectedDate]);

  const refreshData = () => {
    if (alleUserId) {
      const formattedDate = selectedDate.format('YYYY-MM-DD');
      getGenerations(alleUserId, formattedDate)
        .then((res) => {
          setGenerations(res.data.generations);
        })
        .catch((error) => {
          console.error('Error fetching generations:', error);
        });
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <div>
        <DatePicker
          label="Select Date"
          value={selectedDate}
          onChange={(newValue) => setSelectedDate(newValue)}
          renderInput={(params) => <TextField {...params} />}
          sx={{ marginBottom: 2 }}
        />
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} aria-label="my images table">
            <TableHead>
              <TableRow>
                <TableCell>
                  Generated Images
                  <IconButton onClick={refreshData} aria-label="refresh">
                    <RefreshIcon />
                  </IconButton>
                </TableCell>
                <TableCell>Created Date</TableCell>
                <TableCell>Status</TableCell>
                <TableCell></TableCell> {/* Keep this cell for the OpenInNew button */}
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(generations) && generations.length > 0 ? (
                generations.map((generation, index) => (
                  <TableRow key={index} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                    <TableCell>
                      {generation.generation_status === 'generating_images' ? (
                        <Skeleton variant="rectangular" width={50} height={50} />
                      ) : (
                        <div>
                          <img
                            src={generation?.garment_image?.url}
                            alt={`Generated ${generation.public_id}`}
                            style={{ width: 50, height: 50, objectFit: 'cover' }}
                          />
                          <img
                            src={generation?.reference_image?.url}
                            alt={`Generated ${generation.public_id}`}
                            style={{ width: 50, height: 50, objectFit: 'cover', marginLeft: 10 }}
                          />
                        </div>
                      )}
                    </TableCell>
                    <TableCell>{generation?.created_date}</TableCell>
                    <TableCell>
                      <Tooltip
                        title={generation?.review_status?.reject_reason || ''}
                        arrow
                        placement="top"
                      >
                        <Chip
                          label={generation?.display_status}
                          style={{
                            backgroundColor: getChipBackgroundColor(generation?.generation_status),
                            color: '#fff',
                          }}
                          size="small"
                        />
                      </Tooltip>
                    </TableCell>
                    <TableCell>
                      <IconButton
                        color="primary"
                        aria-label="open project"
                        component="a"
                        disabled={generation.generation_status === 'generating_images'}
                        onClick={() =>
                          window.open(
                            `/tR4pvABzrWhmDCi/generated?id=${generation?.public_id}`,
                            '_blank',
                          )
                        }
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    No generations available
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </LocalizationProvider>
  );
}
