import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/authContext';
import { v4 as uuidv4 } from 'uuid';
import Login from './login.jsx';
import Home from './home.jsx';

const CreatorApp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const [id, setId] = useState(queryParams.get('id') || uuidv4());

  const { userLoggedIn, currentUser, alleUserId } = useAuth();

  useEffect(() => {
    if (!queryParams.get('id')) {
      navigate(`?id=${id}`, { replace: true });
    } else if (queryParams.get('id') !== id) {
      setId(queryParams.get('id'));
    }
  }, [queryParams, id, navigate]);

  return userLoggedIn ? (
    <div style={{ margin: '1%' }}>
      <Home id={id} />
    </div>
  ) : (
    navigate('/login')
  );
};

export default CreatorApp;
