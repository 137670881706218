import React, { useState, useEffect } from 'react';
import { Box, TextField, Typography, Grid, Paper } from '@mui/material';
import {
  pairing_sample_text,
  styling_sample_text,
  footwear_sample_text,
  accessories_sample_text,
  bag_sample_text,
} from './constants';
import ImageWithModal from './image.jsx';

export default function StyleDescription({ setGeneratedData, description, garment, reference }) {
  const [formData, setFormData] = useState(description || {});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    setGeneratedData((prevData) => ({
      ...prevData,
      styling_description: formData,
    }));
  }, [formData, setGeneratedData]);

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} sx={{ mb: 4 }}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Garment Image
            </Typography>
            <ImageWithModal src={garment?.url} alt="Garment" />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Paper elevation={3} sx={{ p: 2 }}>
            <Typography variant="h6" gutterBottom>
              Reference Image
            </Typography>
            <ImageWithModal src={reference?.url} alt="Reference" />
          </Paper>
        </Grid>
      </Grid>

      <Typography variant="h6" gutterBottom sx={{ mb: 4 }}>
        Styling Description
      </Typography>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3, mb: 4 }}>
        <TextField
          name="pairing"
          label="Pairing"
          value={formData?.pairing || ''}
          onChange={handleChange}
          fullWidth
          placeholder={pairing_sample_text}
          required
        />
        <TextField
          name="styling"
          label="Styling"
          value={formData?.styling || ''}
          onChange={handleChange}
          fullWidth
          placeholder={styling_sample_text}
        />
        <TextField
          name="accessories"
          label="Accessories"
          value={formData?.accessories || ''}
          onChange={handleChange}
          fullWidth
          placeholder={accessories_sample_text}
        />
        <TextField
          name="bag"
          label="Bag"
          value={formData?.bag || ''}
          onChange={handleChange}
          fullWidth
          placeholder={bag_sample_text}
        />
        <TextField
          name="footwear"
          label="Footwear"
          value={formData?.footwear || ''}
          onChange={handleChange}
          fullWidth
          placeholder={footwear_sample_text}
        />
      </Box>
    </>
  );
}
